import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

//import images
import avatar from "../../assets/images/users/user-icon-png.jpeg";

const Section = (profile) => {

    let Profilepic
    if (profile.profile != null) {
        Profilepic = <img src={profile.profile} alt="" className="avatar-sm rounded" />
    } else {
        Profilepic = <div><i className="bx bxs-user bd-dash-users"></i></div>
    }
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col lg={12}>
                    <div className="d-flex align-items-center display-flex-column">
                        {/* <img src={avatar} alt="" className="avatar-sm rounded" /> */}
                        {Profilepic}
                        <div className="ms-3 flex-grow-1">
                            <h5 className="mb-2 card-title">Hello, {localStorage.getItem("name")}</h5>
                            <p className="text-muted mb-0">{localStorage.getItem("position")}</p>
                        </div>
                        <div className="mobile-mt-20">
                            <h5>Dashboard</h5>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Section;