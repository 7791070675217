import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login2 from "pages/Authentication/Login2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Support
import SupportIndex from "pages/Support"
import AddReply from "pages/Support/AddReply"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

  // Support
  { path: "/support", component: <SupportIndex/> },
  { path: "/add-reply/:id", component: <AddReply/> },

  // //profile
  { path: "/profile", component: <UserProfile/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },

]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login2", component: <Login /> },
  { path: "/login", component: <Login2 /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }