import React, { useMemo, useState, useEffect } from "react"
import { Col, Container, Row, Table, Modal, Form, Card } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import Dropzone from "react-dropzone"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// commom
import Pagination from "components/Common/Pagination"
import swal from "sweetalert"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
// editor
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import GlobalFilter from "pages/GlobalFilter"
import Loader from "pages/Loader"

const SupportIndex = () => {
  const [modal_large, setmodal_large] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }
  // console.log(selectedFiles)
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function tog_large() {
    setmodal_large(!modal_large)
  }

  const navigate = useNavigate()

  const [data, setDatas] = useState([])
  const [OpenData, setOpenData] = useState(false)
  useEffect(() => {
    fetchData()
  }, [OpenData])

  const fetchData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/client_ticket_data",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const newArray = response.data.data.map(item => ({
          ...item,
          created_at: item.created_at.slice(0, 10),
          updated_at: item.updated_at.slice(0, 10),
        }))
        let filteredArray
        if (OpenData === false) {
          filteredArray = newArray.filter(item => item.status !== "Closed")
        } else if (OpenData === true) {
          filteredArray = newArray.filter(item => item.status === "Closed")
        } else {
          filteredArray = newArray
        }
        setDatas(filteredArray)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // console.log(data)

  const priorities = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ]

  const [selected, setselected] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "width-id ",
      },
      {
        Header: "Subject",
        accessor: "subject",
        // isInfoIcon: true
        filterable: true,
      },
      {
        Header: "Project",
        accessor: "project_name",
        // isInfoIcon: true
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        // isInfoIcon: true
        filterable: true,
        className: "status-width",
        Cell: cellProps => {
          const onStatusChange = async e => {
            swal({
              title: "Are you sure?",
              text: "You want to Change the Status !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contact_id", localStorage.getItem("userid"))
                formData.append("support_id", cellProps.row.values.id)
                formData.append("status", e.target.value)
                try {
                  const response = await axios({
                    method: "post",
                    url: "https://mars.zithas.com/api/client_ticket_status_change",
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Status Changed Successfully",
                    }).then(date => setOpenData(true))
                    const newArray = response.data.data.map(item => ({
                      ...item,
                      created_at: item.created_at.slice(0, 10),
                      updated_at: item.updated_at.slice(0, 10),
                    }))
                    const filteredArray = newArray.filter(
                      item => item.status === "Closed"
                    )

                    setDatas(filteredArray)
                    setOpenTicket(
                      response.data.data.filter(item => item.status != "Closed")
                        .length
                    )
                    setClosedTicket(
                      response.data.data.filter(item => item.status == "Closed")
                        .length
                    )
                    setLoading(false)
                  })
                } catch (err) {
                  console.log(err)
                  setLoading(false)
                }
              } else {
                setselected(true)
              }
            })
          }

          let SelectOption
          if (cellProps.row.values.status == "Closed") {
            SelectOption = (
              <span className="font-size-12-5 badge-soft-success badge bg-secondary">
                {cellProps.row.values.status}
              </span>
            )
          } else {
            SelectOption = (
              <select
                className="font-size-12-5 badge-soft-danger badge bg-secondary arrow_none"
                style={{ border: "none" }}
                onChange={onStatusChange}
              >
                <option selected={selected}>Open</option>
                <option value="Closed">Closed</option>
              </select>
            )
          }

          return <div className="arrow_none">{SelectOption}</div>
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        // isInfoIcon: true
        filterable: true,
        className: "priority-width",
      },
      {
        Header: "Last Reply",
        accessor: "updated_at",
        // isInfoIcon: true
        filterable: true,
        className: "date_width ",
      },
      {
        Header: "Created",
        accessor: "created_at",
        // isInfoIcon: true
        filterable: true,
        className: "date_width ",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "width-action ",
        filterable: false,
        Cell: cellProps => {
          const clickCallback = id => {
            navigate(`/add-reply/${cellProps.row.values.id}`)
          }

          const onDeleteTicket = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Ticket !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contact_id", localStorage.getItem("userid"))
                formData.append("support_id", cellProps.row.values.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: "https://mars.zithas.com/api/client_ticket_delete",
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Ticket Deleted Successfully",
                    }).then(data => {
                      setOpenData(false)
                    })
                    const newArray = response.data.data.map(item => ({
                      ...item,
                      created_at: item.created_at.slice(0, 10),
                      updated_at: item.updated_at.slice(0, 10),
                    }))
                    let filteredArray
                    if (OpenData == true) {
                      filteredArray = newArray.filter(
                        item => item.status == "Closed"
                      )
                    } else if (OpenData == false) {
                      filteredArray = newArray.filter(
                        item => item.status != "Closed"
                      )
                    }
                    setDatas(filteredArray)
                    setOpenTicket(
                      response.data.data.filter(item => item.status != "Closed")
                        .length
                    )
                    setClosedTicket(
                      response.data.data.filter(item => item.status == "Closed")
                        .length
                    )
                    setLoading(false)
                  })
                } catch (err) {
                  console.log(err)
                  swal({
                    icon: "error",
                  })
                  setLoading(false)
                }
              }
            })
          }

          return (
            <div>
              <a
                onClick={clickCallback}
                title="Add Reply"
                className="btn action-btn btn-sm me-1 btn-outline-success "
              >
                <i className="fas fa-ticket-alt"></i>
              </a>
              <a
                onClick={onDeleteTicket}
                className="btn btn-sm action-btn btn-danger "
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const [fixedContent, setFixedContent] = useState(false)

  document.title = "Support | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI

  const { pageIndex, pageSize, globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  // Adding Ticket

  const [subject, setSubject] = useState("")
  const [project, setProject] = useState("")
  const [priority, setPriority] = useState("")
  const [description, setDescription] = useState("")

  const onAddTicket = async e => {
    e.preventDefault()
    if (!description) {
      swal({
        icon: "error",
        text: "Description is Required",
      })
    } else {
      setLoading(true)
      const formData = new FormData()
      formData.append("customer_id", localStorage.getItem("customerId"))
      formData.append("contact_id", localStorage.getItem("userid"))
      formData.append("subject", subject)
      formData.append("project_id", project)
      formData.append("priority", priority)
      formData.append("description", description)
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files[]", selectedFiles[i])
      }
      try {
        const response = await axios({
          method: "post",
          url: "https://mars.zithas.com/api/client_add_ticket",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          swal({
            icon: "success",
            text: "Ticket Added Successfully",
          }).then(data => {
            setmodal_large(false)
          })
          const newArray = response.data.data.map(item => ({
            ...item,
            created_at: item.created_at.slice(0, 10),
            updated_at: item.updated_at.slice(0, 10),
          }))
          const filteredArray = newArray.filter(item => item.status != "Closed")
          setDatas(filteredArray)

          setOpenTicket(
            response.data.data.filter(item => item.status != "Closed").length
          )

          setOpenData(false)
          setLoading(false)
        })
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }
  }

  const [isLoading, setLoading] = useState(false)
  const [all_project, setAllProject] = useState([])
  const [defaultValueSelect, setDefaultValue] = useState("")
  const [openTicket, setOpenTicket] = useState("")
  const [closedTicket, setClosedTicket] = useState("")
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const formData = new FormData()
    formData.append("customer_id", localStorage.getItem("customerId"))
    formData.append("contact_id", localStorage.getItem("userid"))
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/client_project_list",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const projects = response.data.data.project_data.map(item => ({
          value: item.id,
          label: item.project_name,
        }))
        const data = response.data.data.project_data.map(item => {
          if (response.data.data.project_data.length == 1) {
            setDefaultValue(item.project_name)
            setProject(item.id)
          }
        })
        setOpenTicket(response.data.data.open)
        setClosedTicket(response.data.data.closed)
        setAllProject(response.data.data.project_data)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  let SelectOption
  if (all_project.length == 1) {
    SelectOption = <option>{defaultValueSelect}</option>
  } else {
    SelectOption = <option value="">select Project</option>
  }
  const [isShown, setShown] = useState(false)

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboards"} breadcrumbItem={"Support"} />
          <div className="p-4 bg-white mb-4 shadow">
            <div className="d-flex justify-content-between display-flex-column resp-mb-20">
              <div className="d-flex justify-space-between mobile-col-12 resp-mb-20">
                <div>
                  <h5>Support Summary</h5>
                </div>
                <div className="">
                  <a
                    className="btn btn-outline-secondary bd-custom-sidebar"
                    onClick={() => setShown(!isShown)}
                  >
                    <i className="fas fa-filter"></i>
                  </a>
                </div>
              </div>
              {/* Reponsive Mobile Filter */}
              <div
                className={
                  isShown
                    ? "mb-2 bd-web-none p-3 pt-0"
                    : "display-none bd-custom-sidebar"
                }
              >
                <div className=" display-flex mb-20">
                  <div className="col-9">Apply Filter</div>
                  <div className="col-3 text-right">
                    <a className="" onClick={() => setShown(!isShown)}>
                      <i className="fas fa-times"></i>
                    </a>
                  </div>
                </div>
                <Col>
                  <select
                    className="form-select bd-resp-margin"
                    value={pageSize}
                    onChange={e => setPageSize(e.target.value)}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              <div>
                <button
                  onClick={() => tog_large()}
                  className="btn btn-purple resp-mr-10"
                >
                  <i className="fas fa-plus me-2 "></i> Add Ticket
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className="border-radius btn ms-md-2 btn-red  "
                >
                  <i className="dripicons-cross me-2 icon-center"></i>CANCEL
                </button>
              </div>
            </div>

            {/* status */}
            <Row className="mt-2 text-center">
              <div className="display-flex mobile-align-iten-center">
                <div
                  className={
                    OpenData
                      ? "mr-30 padding-10"
                      : "mr-30 padding-10  bd-shadow-ticket"
                  }
                >
                  <a
                    className="text-warning"
                    id="open"
                    onClick={() => {
                      setOpenData(false)
                    }}
                  >
                    <h5>{openTicket}</h5>
                    <h4 className="">Open</h4>
                  </a>
                </div>
                <div
                  className={
                    OpenData ? " padding-10  bd-shadow-ticket" : " padding-10 "
                  }
                >
                  <a
                    className="text-dark"
                    onClick={() => {
                      setOpenData(true)
                    }}
                    id=""
                  >
                    <h5>{closedTicket}</h5>
                    <h4>Closed</h4>
                  </a>
                </div>
              </div>
            </Row>

            <Row className="resp-mt-20">
              <div className="">
                {/* <TableContainer columns={columns} data={data} /> */}
                <Row className="mt-4 bd-reposive-none">
                  <Col>
                    <select
                      className="border-radius form-select bd-resp-margin"
                      style={{ width: "100px" }}
                      value={pageSize}
                      onChange={e => setPageSize(e.target.value)}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </Row>
                <div className="table-responsive react-table">
                  <Table
                    className="table table-hover table-bordered"
                    {...getTableProps()}
                  >
                    <thead
                      className={`${
                        fixedContent ? "fixed-setting-header" : ""
                      } table-light table-nowrap`}
                    >
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {data.length === 0 ? (
                      <tbody>
                        <tr>
                          <td colSpan={columns.length} className="text-center no-data">
                            No Data is Available
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                          prepareRow(row)
                          return (
                            <tr key={row.id} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps()}
                                    data-column={cell.column.Header}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    )}
                  </Table>
                </div>

                {/* Pagination */}
                <Row className="justify-content-md-end justify-content-center align-items-center">
                  <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                  />
                </Row>
              </div>
            </Row>
          </div>

          {/* Add Ticket Modal */}
          <Modal
            size="lg"
            isOpen={modal_large}
            toggle={() => {
              tog_large()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                New Ticket
              </h5>
              <button
                onClick={() => {
                  setmodal_large(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form onSubmit={onAddTicket}>
                <Row className="justify-content-between">
                  <div className="col-12 mb-3">
                    <label htmlFor="">Subject</label>
                    <input
                      type="text"
                      placeholder="Enter Subject"
                      className="form-control"
                      required
                      onChange={e => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">Project</label>
                    <select
                      className="form-control"
                      required
                      onChange={e => setProject(e.target.value)}
                    >
                      {SelectOption}
                      {all_project.map((item, index) => {
                        if (all_project.length != 1) {
                          return (
                            <option key={index} value={item.id}>
                              {item.project_name}
                            </option>
                          )
                        }
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="">Priority</label>
                    <select
                      className="form-control"
                      onChange={e => setPriority(e.target.value)}
                      required
                    >
                      <option value="">Select Priority</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>

                  <div className="col-12 mb-3">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setDescription(data)
                      }}
                      required
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="">Attachment</label>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="dz-message needsclick p-0"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="d-flex px-2 custom-file-upload align-items-center">
                            <div className="">
                              <i
                                className="display-5 text-muted bx bxs-cloud-upload"
                                style={{ fontSize: "35px" }}
                              />
                            </div>
                            <h5
                              className="mb-0 ms-2"
                              style={{ fontSize: "14px" }}
                            >
                              Upload Files
                            </h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  {/* file display here */}
                  <div
                    className={`col-md-6 dropzone-previews ${
                      selectedFiles.length > 0 ? "d-block" : "d-none"
                    }`}
                    id="file-previews"
                  >
                    <label className="mb-1">Uploaded Files</label>
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-1">
                        <Row className="align-items-center justify-content-evenly">
                          {selectedFiles.map((f, i) => {
                            return (
                              <Col key={i} className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </Card>
                  </div>
                  <div className="border-bottom pb-2"></div>
                  <div className="mt-3 text-end">
                    <button className="btn btn-purple">Submit</button>
                  </div>
                </Row>
              </Form>
            </div>
          </Modal>
          {/* ------------ */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SupportIndex
