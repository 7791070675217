import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import axios from "axios"
import { Table, Row, Col, Modal, Form, Card } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import Pagination from "./Pagination"
import Dropzone from "react-dropzone"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Loader"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4} className="d-flex justify-content-end">
        <div className="search-box pe-0 d-inline-block">
          <div className="position-relative text-end">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control rounded"
                placeholder={`Search...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  all_project,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const [modal_large, setmodal_large] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  const priorities = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ]

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [fixedContent, setFixedContent] = useState(false)

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const projects = all_project.map(item => ({
    value: item.id,
    label: item.project_name,
  }))

  // Adding Ticket Integration
  let defaultValues
  let defaultId
  let projectSingle
  const datas = all_project.map(item => {
    if (all_project.length == 1) {
      defaultValues = item.project_name
      defaultId = item.id
      projectSingle = "true"
    } else {
      projectSingle = "false"
    }
  })

  const [isLoading, setLoading] = useState(false)
  const [subject, setSubject] = useState("")
  const [project, setProject] = useState("")
  const [priority, setPriority] = useState("")
  const [description, setDescription] = useState("")

  const onAddTicket = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", localStorage.getItem("customerId"))
    formData.append("contact_id", localStorage.getItem("userid"))
    formData.append("subject", subject)
    if (projectSingle == "true") {
      formData.append("project_id", defaultId)
    } else if (projectSingle == "false") {
      formData.append("project_id", project)
    }

    formData.append("priority", priority)
    formData.append("description", description)
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i])
    }
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/client_add_ticket",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Ticket Added Successfully",
        }).then(data => {
          setmodal_large(false)
          window.location.reload()
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      swal({
        icon: "error",
        text: "Something Went Wrong",
      })
      setLoading(false)
    }
  }

  let SelectOption
  if (all_project.length == 1) {
    SelectOption = <option>{defaultValues}</option>
  } else {
    SelectOption = <option value="">select Project</option>
  }

  const [isShown, setShown] = useState(false)

  return (
    <Fragment>
      {isLoading && <Loader />}
      <div className="d-flex justify-space-between">
        <div className="mb-4 h4 card-title">Latest Ticket</div>
        <div className="">
          <a
            className="btn btn-outline-secondary bd-custom-sidebar"
            onClick={() => setShown(!isShown)}
          >
            <i className="fas fa-filter"></i>
          </a>
        </div>
      </div>

      <Row className="mb-2 justify-content-between bd-reposive-none">
        <Col md={2} style={{ width: "120px" }}>
          <select
            className="form-select bd-resp-margin"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col
          md={8}
          className="d-flex justify-content-end align-items-center display-flex-column responsive-right"
        >
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter}
              className="mt-0 pt-0"
            />
          )}
          <button
            onClick={() => tog_large()}
            className="btn btn-purple mb-1 ms-4"
          >
            <i className="fas fa-plus me-2 "></i> Add Ticket
          </button>
        </Col>
      </Row>

      {/* Reponsive Mobile Filter */}
      <div
        className={
          isShown ? "mb-2 bd-web-none p-3 pt-0" : "display-none bd-custom-sidebar"
        }
      >
        <div className=" display-flex mb-20">
          <div className="col-9">Apply Filter</div>
          <div className="col-3 text-right">
            <a className=""  onClick={() => setShown(!isShown)}>
              <i className="fas fa-times"></i>
            </a>
          </div>
        </div>
        <Col>
          <select
            className="form-select bd-resp-margin"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end align-items-center display-flex-column responsive-right">
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter}
              className="mt-0 pt-0"
            />
          )}
        </Col>
      </div>

      <button
        onClick={() => tog_large()}
        className="btn btn-purple  mb-20 bd-web-none"
      >
        <i className="fas fa-plus me-2 "></i> Add Ticket
      </button>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead
            className={`${
              fixedContent ? "fixed-setting-header" : ""
            } table-light table-nowrap`}
          >
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.className}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan={columns.length} className="text-center no-data">
                  No Data is Available
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      if (data.length == 0) {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            data-column={cell.column.Header}
                            className={cell.column.className}
                          >
                            No Data is Available
                          </td>
                        )
                      } else {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            data-column={cell.column.Header}
                            className={cell.column.className}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add Ticket Modal */}
      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            New Ticket
          </h5>
          <button
            onClick={() => {
              setmodal_large(false)
              setselectedFiles([])
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddTicket}>
            <Row className="justify-content-between">
              <div className="col-12 mb-3">
                <label htmlFor="">Subject</label>
                <input
                  type="text"
                  placeholder="Enter Subject"
                  className="form-control"
                  onChange={e => setSubject(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="">Project</label>
                <select
                  className="form-control"
                  required
                  onChange={e => setProject(e.target.value)}
                >
                  {SelectOption}
                  {all_project.map((item, index) => {
                    if (all_project.length != 1) {
                      return (
                        <option key={index} value={item.id}>
                          {item.project_name}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="">Priority</label>

                <select
                  className="form-control"
                  onChange={e => setPriority(e.target.value)}
                  required
                >
                  <option value="">Select Priority</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>

              <div className="col-12 mb-3">
                <label htmlFor="">Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDescription(data)
                  }}
                />
              </div>
              <div className="col-6 mb-3 mobile-w-100">
                <label htmlFor="">Attachment</label>
                {/* <input type="file" className='form-control' multiple /> */}
                {/* custom file upload */}
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="dz-message needsclick p-0"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="d-flex px-2 display-flex-column custom-file-upload align-items-center">
                        <div className="">
                          <i className="display-5 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h5 className="mb-0 ms-2">Upload Files</h5>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              {/* file display here */}
              <div
                className={`col-md-6 dropzone-previews ${
                  selectedFiles.length > 0 ? "d-block" : "d-none"
                }`}
                id="file-previews"
              >
                <label className="mb-1">Uploaded Files</label>
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-1">
                    <Row className="align-items-center justify-content-evenly">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Col key={i} className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </Card>
              </div>
              <div className="border-bottom pb-2"></div>
              <div className="mt-3 text-end">
                <button className="btn btn-purple">Submit</button>
              </div>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
