import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay" style={{opacity:"1"}} ></div>
            <div className="d-flex h-100 flex-column">
              <div className="" style={{marginTop:"400px"}}>
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="text-center">
                      <h4 className="mb-10 bd-custom-maring"  >
                        <i className="bx bxs-quote-alt-left text-white h1 align-middle me-3"></i>
                        <span className="text-white-login bd-home-text" > Your unwavering support and trust in our services have been the cornerstone of our success. Thank you for being an incredible client!</span>
                        <i className="bx bxs-quote-alt-right text-white h1 align-middle ml-15"></i>
                      </h4>
                      <div dir="ltr">
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
