import React from 'react'

const Pagination = ({gotoPage, previousPage, canPreviousPage, nextPage, canNextPage, pageIndex,pageOptions}) => {
    return (
        <>
            <div className='d-flex justify-content-between align-items-center display-flex-column resp-mt-10'>
                <p>Showing {pageIndex+1} to {pageOptions.length} of {pageOptions.length} entries</p>

                <nav aria-label="...">
                    <ul className="pagination">
                        <li className={`page-item ${canPreviousPage ? '' : 'disabled'} `} onClick={previousPage}>
                            <a className="page-link">Previous</a>
                        </li>

                        {
                            pageOptions.map((page) => (
                                <li key={page} onClick={() => gotoPage(page)}
                                    className={`page-item`}>
                                    <a className={`page-link ${page === pageIndex ? 'active' : ''} `} >{page+1}</a>
                                </li>
                            ))
                        }

                        <li className={`page-item ${canNextPage ? '' : 'disabled'} `} onClick={nextPage}>
                            <a className="page-link" >Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Pagination