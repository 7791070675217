import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
  Alert,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"
import axios from "axios"
// import images
import zithasLogo from "../../assets/images/zithas.png"
import CarouselPage from "./CarouselPage"
import Loader from "pages/Loader"

const Login2 = props => {
  const [passwordShow, setPasswordShow] = useState(false)

  //meta title
  document.title = "Login | Zithas Client"

  const dispatch = useDispatch()

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@zithasclient.com" || "",
      password: "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    }
  }

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [error2, setError2] = useState(false)
  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  const navigate = useNavigate()

  const onHandleLogin = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/client_login",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.success == false) {
          setError2(true)
        } else if (response.data.success == true) {
          const data = response.data.data.map((item, index) => {
            localStorage.setItem("userid", item.id)
            localStorage.setItem("customerId", item.customer_id)
            localStorage.setItem("name", item.name)
            localStorage.setItem("position", item.position)
          })
          navigate("/dashboard")
        }
        setLoading(false)
      })
    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  if (
    !localStorage.getItem("name") &&
    !localStorage.getItem("userid") &&
    !localStorage.getItem("customerId")
  ) {
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content bg-white p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img
                            src={zithasLogo}
                            alt=""
                            className="logo-dark-element bd-logo-login"
                          />
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">
                            Get the support you need !
                          </h5>
                          <p className="text-muted">
                            Sign in and let us assist you <br/> on your journey.
                          </p>
                        </div>

                        <div className="mt-4">
                          <Form
                            className="form-horizontal"
                            onSubmit={onHandleLogin}
                          >
                            {error2 ? (
                              <Alert color="danger">
                                Username and password are invalid. Please enter
                                correct username and password
                              </Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label className="form-label">Username</Label>
                              <Input
                                name="username"
                                className="form-control"
                                placeholder="Enter username"
                                type="text"
                                required
                                onChange={e => {
                                  setEmail(e.target.value)
                                  setError2(false)
                                }}
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                // value={validation.values.email || ""}
                                // invalid={
                                //   validation.touched.email && validation.errors.email ? true : false
                                // }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <div className="position-relative">
                                <Input
                                  name="password"
                                  className="w-100"
                                  required
                                  // value={validation.values.password || ""}
                                  onChange={e => {
                                    setPassword(e.target.value)
                                    setError2(false)
                                  }}
                                  type={passwordType}
                                  placeholder="Enter Password"
                                  // onChange={validation.handleChange}
                                  // onBlur={validation.handleBlur}
                                  // invalid={
                                  //   validation.touched.password && validation.errors.password ? true : false
                                  // }
                                />
                                <a
                                  className="bd-pass-icon"
                                  onClick={togglePassword}
                                >
                                  {passwordType === "password" ? (
                                    <i className="fas fa-eye-slash "></i>
                                  ) : (
                                    <i className="fas fa-eye "></i>
                                  )}
                                </a>
                              </div>
                              {/* <div className="text-right mt-5">
                                <Link
                                  to="/auth-recoverpw-2"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div> */}
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-check mt-20">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="auth-remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  } else {
    navigate("/dashboard")
  }
}

export default withRouter(Login2)
